exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-autonomous-navigation-and-slam-tsx": () => import("./../../../src/pages/autonomous-navigation-and-slam.tsx" /* webpackChunkName: "component---src-pages-autonomous-navigation-and-slam-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-org-assistant-tsx": () => import("./../../../src/pages/org-assistant.tsx" /* webpackChunkName: "component---src-pages-org-assistant-tsx" */),
  "component---src-pages-qpp-website-tsx": () => import("./../../../src/pages/qpp-website.tsx" /* webpackChunkName: "component---src-pages-qpp-website-tsx" */),
  "component---src-pages-robot-arm-tsx": () => import("./../../../src/pages/robot-arm.tsx" /* webpackChunkName: "component---src-pages-robot-arm-tsx" */),
  "component---src-pages-simple-subs-tsx": () => import("./../../../src/pages/simple-subs.tsx" /* webpackChunkName: "component---src-pages-simple-subs-tsx" */),
  "component---src-pages-speaker-tsx": () => import("./../../../src/pages/speaker.tsx" /* webpackChunkName: "component---src-pages-speaker-tsx" */)
}

