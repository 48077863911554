const NavLinks: NavLink[] = [
  {
    title: "My Work",
    to: "/"
  },
  {
    title: "About Me",
    to: "/about/"
  }
];

export default NavLinks;